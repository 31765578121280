import { Injectable } from '@angular/core';
import { ajax } from 'rxjs/ajax';
import { Logger } from './logger.service';
import { EXTERNAL_APIS, Lode3V1PoolABI, Lode3V1PoolAddress, SYS_PER_ASSET_TX } from '../constants';
import { Store } from '@ngrx/store';
import { AppState, getPriceOracleState } from '../store/appState';
import { PriceOracleState } from '../store/priceOracle';
import { SetPriceOracleDataAction } from '../actions/priceOracle.actions';
import BigNumber from 'bignumber.js';
import { AvalancheService } from './avalanche.service';
import { Contract, ethers, providers, utils } from 'ethers';
import { isAGXKeyringId, isAUXKeyringId, isLodeKeyringId } from '../utils';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class PriceOracleService {
  private url = EXTERNAL_APIS.MEMBERS_PORTAL + '/publicinfo';
  private cacheTimeoutMinutes = 0.5; // minutes
  private priceOracleCache: PriceOracleState;
  private lastUpdateTime: number = 0; // track last update time outside of ngrx lifecycle
  private refreshCachePromise;
  private refreshCachePromiseResolved = false;

  constructor(
    protected store: Store<AppState>,
    private avalancheService: AvalancheService,
    private networkService: NetworkService
  ) {
    this.store.select(getPriceOracleState).subscribe((state) => {
      this.priceOracleCache = state;
    });
  }

  public refreshCache(forceRefresh = false) {
    if (!this.refreshCachePromise || this.refreshCachePromiseResolved) {
      const timeoutMs = this.cacheTimeoutMinutes * 60 * 1000;
      if (this.lastUpdateTime < Date.now() - timeoutMs || forceRefresh) {
        Logger.info('Price oracle cache MISS');
        this.refreshCachePromiseResolved = false;
        this.lastUpdateTime = Date.now();
        this.refreshCachePromise = new Promise(async (resolve) => {
          const priceOracleResponse: any = await ajax.getJSON(this.url).toPromise();
          const oracleState: PriceOracleState = {
            agxMarkup: priceOracleResponse.data.agx_rate,
            auxMarkup: priceOracleResponse.data.aux_rate,
            agxRates: priceOracleResponse.data.oracle.agx,
            auxRates: priceOracleResponse.data.oracle.aux,
            silverRates: priceOracleResponse.data.oracle,
            lodeRate: priceOracleResponse.data.lode_rate,
            tx_fee: priceOracleResponse.data.tx_fee,
            lastUpdated: this.lastUpdateTime,
            lodePrice: priceOracleResponse.data?.lode_price,
            lodeMarketPrice: priceOracleResponse.data?.lode_market_price,
            agxMarketPrice: priceOracleResponse.data?.agx_market_price,
            auxMarketPrice: priceOracleResponse.data?.aux_market_price,
          };
          this.priceOracleCache = oracleState;
          this.store.dispatch(new SetPriceOracleDataAction({ data: oracleState }));
          resolve(oracleState);
          this.refreshCachePromiseResolved = true;
        });

        return this.refreshCachePromise;
      } else {
        Logger.info('Price oracle cache HIT');
      }
    } else {
      Logger.info('> MISS wait for existing promise');
      return this.refreshCachePromise;
    }
  }

  async getSpotPrice(asset: 'agx' | 'aux' | 'all', forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      if (asset === 'all') {
        return this.priceOracleCache;
      } else {
        return asset === 'agx' ? this.priceOracleCache.agxRates.quotes : this.priceOracleCache.auxRates.quotes;
      }
    } catch (e) {
      Logger.info(`Oracle query failed for asset ${asset}`);
    }
  }

  async getMarkup<O>(asset: 'agx' | 'aux' | 'all', forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      if (asset === 'all') {
        return { agx: this.priceOracleCache.agxMarkup, aux: this.priceOracleCache.auxMarkup };
      } else {
        return asset === 'agx' ? this.priceOracleCache.agxMarkup : this.priceOracleCache.auxMarkup;
      }
    } catch (e) {
      Logger.info(`${asset} markup price failed`);
    }
  }

  async getRetailFiatValue(asset: 'agx' | 'aux') {
    const spot: any = await this.getSpotPrice(asset, true);
    const markup: any = await this.getMarkup(asset, true);

    return new BigNumber(spot[0].lastPrice).multipliedBy(markup);
  }

  async getBuyBackPrice(baseCurrency: string, asset: 'agx' | 'aux') {
    let buyBackValue = 0;
    switch (asset) {
      case 'agx':
        try {
          const entry = this.priceOracleCache.agxRates.quotes.find(
            (item) => item.currency.toLocaleLowerCase() === baseCurrency.toLocaleLowerCase()
          );
          buyBackValue = parseFloat(entry[`buyBackPrice`]);
        } catch (e) {
          Logger.info('Error querying oraclePriceService for buy back price of asset: ', asset);
        }
        break;
      case 'aux':
        try {
          const entry = this.priceOracleCache.auxRates.quotes.find(
            (item) => item.currency.toLocaleLowerCase() === baseCurrency.toLocaleLowerCase()
          );
          buyBackValue = parseFloat(entry[`buyBackPrice`]);
        } catch (e) {
          Logger.info('Error querying oraclePriceService for buy back price of asset: ', asset);
        }
        break;
      default:
        break;
    }
    return buyBackValue;
  }

  async getSysFee(forceRefresh = false) {
    await this.refreshCache(forceRefresh);
    try {
      return this.priceOracleCache.tx_fee;
    } catch (e) {
      Logger.info('fetch SYS TX fee failed. Falling back to constant: ', SYS_PER_ASSET_TX);
      return SYS_PER_ASSET_TX;
    }
  }

  async getValueInBaseCurrency(tokenSymbols, baseCurrencyId: string[], forceRefresh: boolean = false): Promise<any> {
    await this.refreshCache(forceRefresh);
    const response = {};
    const baseCurrency = baseCurrencyId.join(',').toLocaleLowerCase();
    await tokenSymbols.forEachAsync(async (keyringId) => {
      try {
        if (isAGXKeyringId(keyringId)) {
          const entryHLAGX = this.priceOracleCache.agxRates.quotes.find(
            (item) => item.currency.toLocaleLowerCase() === baseCurrency
          );
          response[keyringId] = parseFloat(entryHLAGX.lastPrice);
        } else if (isAUXKeyringId(keyringId)) {
          const entryPTAUX = this.priceOracleCache.auxRates.quotes.find(
            (item) => item.currency.toLocaleLowerCase() === baseCurrency
          );
          response[keyringId] = parseFloat(entryPTAUX.lastPrice);
        } else if (isLodeKeyringId(keyringId)) {
          response[keyringId] = new BigNumber(this.priceOracleCache.lodePrice).toNumber();
        }
      } catch (err) {
        Logger.error('getValueInBaseCurrency', keyringId);
      }
    });

    return {
      baseCurrency,
      tx_fee: this.priceOracleCache.tx_fee,
      fiatValues: response,
    };
  }

  async getLodeTokenPriceFromV1Pool() {
    const network = await this.networkService.getActiveAvaxNetwork();
    const provider = providers.getDefaultProvider(network.RPC_URL);
    const lodePool = new Contract(Lode3V1PoolAddress, new utils.Interface(Lode3V1PoolABI), provider);
    const res = await lodePool.getReserves();
    console.log({res})
    return res[0].toString() * 1.0 / (res[1].toString().slice(0, 12));
  }

  async getMarketPriceFromLodeBackend(tokenSymbols, baseCurrencyId: string[], forceRefresh: boolean = false): Promise<any> {
    await this.refreshCache(forceRefresh);
    const response = {};
    const baseCurrency = baseCurrencyId.join(',').toLocaleLowerCase();
    await tokenSymbols.forEachAsync(async (keyringId) => {
      try {
        if (isAGXKeyringId(keyringId)) {
          let quote = this.priceOracleCache.agxRates.quotes.find(
            (quote) => quote.currency.toLowerCase() == baseCurrency.toLowerCase()
          );
          response[keyringId] = quote.lastPrice;
        } else if (isAUXKeyringId(keyringId)) {
          let quote = this.priceOracleCache.auxRates.quotes.find(
            (quote) => quote.currency.toLowerCase() == baseCurrency.toLowerCase()
          );
          response[keyringId] = quote.lastPrice;
        } else if (isLodeKeyringId(keyringId)) {
          let lodePriceFromV1Pool = 0.0
          try {
            lodePriceFromV1Pool = await this.getLodeTokenPriceFromV1Pool();
            console.log({ lodePriceFromV1Pool })
          } catch(err) {
            lodePriceFromV1Pool = this.priceOracleCache.lodeMarketPrice[baseCurrency]
          }

          response[keyringId] = lodePriceFromV1Pool;
        }
      } catch (err) {
        Logger.error('getValueInBaseCurrency', keyringId);
      }
    });
    Logger.info(`getMarketPriceFromLodeBackend - ${tokenSymbols} - ${response}`);
    return {
      baseCurrency,
      tx_fee: this.priceOracleCache.tx_fee,
      fiatValues: response,
    };
  }
}
